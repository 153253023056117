import React, { useState, useEffect } from "react"
import * as luxon from "luxon"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import SbEditable from "storyblok-react"
import useStoryblok from "../utils/storyblok"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import DynamicComponent from "../components/DynamicComponent"
import Footer from "../components/Footer"
import Search from "../components/search/Search"
import Slider from "../components/slider/Slider"
import doggyImage from "../images/278132126_384113933578325_2212920422578428876_n.jpeg"
import giftFucker from "../images/278201268_1748302582167945_3773849255869030189_n.jpeg"
import nadCunt from "../images/278234275_290920573215908_2122508792329180039_n.jpeg"
import pods from "../images/278362009_408432977311758_6116022313969556397_n.jpeg"
import shopBanner from "../images/278362009_408432977311758_6116022313969556397_n.png"

const ImageData = [
  {
    image: doggyImage,
    url: "https://opensea.io/collection/ethelonia-elonia-dogelon-mars",
  },
  {
    image: shopBanner,
    url: "/contact/",
  },
]

function trim(str) {
  return str.replace(/^\s+|\s+$/g, "")
}

const hostURL =
  process.env.HOST === undefined ? process.env.HOST : process.env.HOST

const ShospmartBlog = ({ data, location }) => {
  let story = data.allStoryblokEntry["edges"]
  story = useStoryblok(story, location)

  let articleArray = []
  {
    story.map(article => articleArray.push(article.node))
  }

  let contentArray = []
  {
    story.map(article => contentArray.push(JSON.parse(article.node.content)))
  }

  for (let step = 0; step < contentArray.length; step++) {
    contentArray[step]["full_slug"] =
      articleArray[step].full_slug || "NOT-SUPPLIED"
    if (contentArray[step].articleImage) {
      contentArray[step]["articleImageFileName"] =
        contentArray[step].articleImage.filename || "NOT-SUPPLIED"
      contentArray[step]["articleImageAltText"] =
        contentArray[step].articleImage.alt || ""
    }

    if (contentArray[step].avatar) {
      contentArray[step]["avatar"] =
        contentArray[step].avatar.filename || "NOT-SUPPLIED"
    }

    contentArray[step]["reading_time"] = contentArray[step]["readingTime"]
  }

  const sorted = [...contentArray].sort(function (a, b) {
    var c = luxon.DateTime.fromJSDate(new Date(a.date), {
      locale: "en-AU",
    })
    var d = luxon.DateTime.fromJSDate(new Date(b.date), {
      locale: "en-AU",
    })
    return d < c ? d - c : -1
  })

  const DATE_OPTIONS = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  }

  return (
    <Layout>
      <Seo
        title="Shopsmart Blog"
        description="Shopsmart Loyalty Card and E-Wallet. The only wallet you will ever need. ecommerce business, e-commerce, rewards deals"
        pageName="shopsmart-blog"
      />
      <Slider slides={ImageData} />
      <div className="blog relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
        <div className="absolute inset-0">
          <div className="bg-white h-1/3 sm:h-2/3" />
        </div>
        <div className="relative max-w-7xl mx-auto">
          <div className="text-center">
            <h1 class="sr-only">Welcome to the Shopsmart blog!</h1>
            <h2>From the Shopsmart blog</h2>
            <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
              The list below is of our latest articles. We will be updating
              regularly so please check back often.
            </p>
            <Search data={story} />
          </div>
          <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
            {sorted.slice(0, 3).map(post => (
              <div
                key={post.title}
                className="flex flex-col rounded-lg shadow-lg overflow-hidden"
              >
                <div className="flex-shrink-0">
                  <img
                    className="h-48 w-full object-cover"
                    src={post.articleImageFileName}
                    alt={post.articleImageAltText}
                    loading="lazy"
                  />
                </div>

                <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                  <div className="flex-1">
                    <p className="text-sm font-medium text-indigo-600">
                      <a
                        href={`${hostURL}/${post.full_slug}`}
                        className="hover:underline"
                      >
                        {post.postType}
                      </a>
                    </p>

                    <a
                      href={`${hostURL}/${post.full_slug}`}
                      className="block mt-2"
                    >
                      <p className="text-xl font-semibold text-gray-900">
                        {post.title}
                      </p>
                      <p className="mt-3 text-base text-gray-500">
                        {post.description}
                      </p>
                    </a>
                  </div>
                  <div className="mt-6 flex items-center">
                    <div className="flex-shrink-0">
                      {/* <a href={"post.author.href"}> */}
                      <span className="sr-only"> {post.author}</span>
                      <img
                        async="on"
                        className="h-10 w-10 rounded-full"
                        src={post.avatar}
                        alt={post.articleImageAltText}
                      />
                      {/* </a> */}
                    </div>
                    <div className="ml-3">
                      <p className="text-sm font-medium text-gray-900">
                        {post.author}
                      </p>
                      <div className="flex space-x-1 text-sm text-gray-500">
                        <time dateTime={post.datetime}>
                          {" "}
                          {post.date.slice(0, 11)}
                        </time>
                        <span aria-hidden="true">&middot;</span>
                        <span>{post.reading_time} min read</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export default ShospmartBlog

export const query = graphql`
  query PostQuery {
    allStoryblokEntry(
      filter: { field_component: { eq: "Article" } }
      sort: { fields: first_published_at, order: ASC }
    ) {
      edges {
        node {
          slug
          full_slug
          field_component
          content
          first_published_at
        }
      }
    }
  }
`
