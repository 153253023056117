import React, { useState } from "react"

const Slider = ({ slides }) => {
  const [current, setCurrent] = useState(0)
  const length = slides.length

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1)
  }

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1)
  }

  setTimeout(nextSlide, 6000)

  return (
    <div className="flex">
      <div className="w-3/12">&nbsp;</div>

      {slides.map((slide, index) => {
        return (
          <div className="mt-6 h-56">
            {index === current && (
              <div className="w-9/12 inline" key={index}>
                <a
                  href={slide.url}
                  target={slide.url === "/contact/" ? "_self" : "_blank"}
                >
                  <img className="text-center" src={slide.image} alt="" />
                </a>
              </div>
            )}
          </div>
        )
      })}

      <div className="w-3/12">&nbsp;</div>
    </div>
  )
}

export default Slider
