import React, { useState } from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import SbEditable from "storyblok-react"
import useStoryblok from "../../utils/storyblok"
import { SortAscendingIcon, UsersIcon } from "@heroicons/react/solid"

const hostURL =
  process.env.HOST === undefined ? process.env.HOST : process.env.HOST

const Search = ({ data }) => {
  const [dataList, setData] = useState([])
  const [looked, setLooked] = useState("")
  const [inputValue, setInputValue] = useState("")

  const clearInput = event => {
    event.preventDefault()
    setData([])
    setLooked("")
    setInputValue("")
  }

  const handleInputChange = (event, data) => {
    setInputValue(event.target.value)
    const query = event.target.value
    setLooked("yep")

    const posts = data || []

    const filteredData = posts.filter(post => {
      const { description, title } = JSON.parse(post.node.content)
      if (description === undefined || title === undefined) {
        return
      }
      return (
        description.toLowerCase().includes(query.toLowerCase()) ||
        title.toLowerCase().includes(query.toLowerCase())
      )
    })

    setData(filteredData)
  }

  function parseTitle(str) {
    const title = JSON.parse(str)
    return title.title
  }
  const newlist = dataList.map(item => ({
    slug: item.node.full_slug,
    title: parseTitle(item.node.content),
  }))

  return (
    <>
      <label
        htmlFor="email"
        className="block text-sm font-medium text-gray-700 mt-10"
      >
        Search blog posts
      </label>
      <div className="mt-1 flex rounded-md shadow-sm sm:w-full md:w-2/3 ml-auto mr-auto">
        <div className="relative flex items-stretch flex-grow focus-within:z-10">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M10 21h7a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v11m0 5l4.879-4.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242z"
              />
            </svg>
          </div>
          <input
            type="text"
            name="search"
            id="search"
            autoFocus
            value={inputValue}
            className="focus:ring-red-500 focus:border-red-500 block w-full rounded-none rounded-l-md pl-10 sm:text-sm border-gray-300"
            placeholder="type something..."
            onChange={e => handleInputChange(e, data)}
            autocomplete="off"
          />
        </div>
        <button
          onClick={e => clearInput(e)}
          type="button"
          className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:none focus:none"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <span>Clear</span>
        </button>
      </div>
      <ul className="w-2/3 mt-4 w-2/3 ml-auto mb-0 mr-auto text-left">
        {newlist.slice(0, 5).map(article => (
          <li key={article.title} className="py-4 flex border-b-2">
            {/* <a href={`//${location.host}/${article.slug}`} className="ml-3"> */}
            <a href={`${hostURL}/${article.slug}`} className="ml-3">
              <p className="text-sm font-medium text-gray-900">
                {article.title}
              </p>
              <a
                href={`${hostURL}/${article.slug}`}
                className="text-sm text-gray-500"
              >
                read it
              </a>
            </a>
          </li>
        ))}

        {newlist.length === 0 && looked === "yep" ? (
          <li> Unable to find a result for your search </li>
        ) : (
          <li> </li>
        )}
      </ul>
    </>
  )
}

export default Search
